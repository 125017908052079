import React, { forwardRef, ReactNode, useEffect } from "react";
import { obsoleteWarning } from "@telia/b2x-obsolete-component";

import "./b2x-telia-text-spacing.scss";

type Props = React.HTMLProps<HTMLDivElement> & {
  children: ReactNode;
  ["data-testid"]?: string;
};

const TeliaTextSpacingComponent = <T extends HTMLDivElement>(
  { children, ...rest }: Props,
  ref: React.ForwardedRef<T>
) => {
  useEffect(() => {
    obsoleteWarning("b2x-telia-text-spacing", "utils-textspacing--docs");
  }, []);

  return (
    <div ref={ref} className="b2x-telia-text-spacing" {...rest}>
      {children}
    </div>
  );
};

export const TeliaTextSpacing = forwardRef(TeliaTextSpacingComponent);
TeliaTextSpacing.displayName = "TeliaTextSpacing";
